
<script>
import {
  XlsxWorkbook,
  XlsxSheet,
  XlsxDownload
} from 'vue-xlsx/dist/vue-xlsx.es.js'

export default {
  data () {
    return {
      file: null,
      selectedSheet: null,
      sheetName: 'sampleSheet',
      dataObj: {
        data: {
          name: 'john doe',
          email: 'jdoe@email.com',
          phone: '555-555-5555'
        }
      },
      sheets: [{ name: this.sheetName, data: [{ data: this.dataObj }] }],
      myToggle: false,
      files: false
    }
  },
  components: {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload
  },
  methods: {
    onChange (event) {
      this.file = event.target.files ? event.target.files[0] : null
    },
    addSheet () {
      this.myToggle = true
      this.sheets.push({ name: this.sheetName, data: [this.dataObj] })
      this.sheetName = null
    },
    uploadFile () {
      this.files = true
    }
  }
}
</script>

<style>
</style>
<template>
  <div>
      <section>
      <div class="card bg-white"> <h1 class="text-white my-3 mx-auto w-50 bg-success">Try it out</h1> </div>
      <div v-if="!files" @click="uploadFile">
          <button class="btn btn-light btn-dark">Upload file</button>
      </div>
      <div v-if="files">
      <div>
        <button class="btn btn-light btn-dark" v-if="sheetName" @click="addSheet">Populate Sheet</button>
      </div>
      <div v-if="!files"> {{ sheetName }}</div>
      <div v-if="files">
      <xlsx-workbook>
        <xlsx-sheet
          :collection="sheet.data"
          v-for="sheet in sheets"
          :key="sheet.name"
          :sheet-name="sheet.name"
        />
        <xlsx-download>
          <button class="btn btn-light btn-dark">Download</button>
        </xlsx-download>
      </xlsx-workbook>
      </div>
      </div>
    </section>
  </div>
</template>
