
<script>
export default {

}
</script>

<style>
.imgStyling {
    height: 100%;
    object-fit: cover;
}
.rowContainer {
    background-color: rgb(211, 239, 248);
    width: 100%;
}
</style>
<template>
  <div>
      <div class="card border-0">
          <div class="rowContainer mx-auto">
            <h2 class="my-3">Using the PT<label class="text-success">X</label> file converter is as easy as uploading a file and clicking download</h2>
          </div>
          <div class="row rowContainer mx-auto">
          <div class="col">
              <h3>Files will be covertered from pdf to xlsx for easy data transfer</h3>
          </div>
          <div class="col" style="height: 300px">
              <img class="imgStyling" src="images/search.jpg" />
          </div>
          </div>
          <div class="row rowContainer mx-auto">
              <div class="col container" style="height: 100px;">
                  <img class="imgStyling my-5" src="images/browsers.jpg" />
              </div>
              <div class="col my-5" style=""><h4>
              Unlike natively installed programs, you are not limited by your operating system.
              The PTX file converter will work on all modern browsers such as Chrome, Firefox and Edge but we cannot ensure it will work on
              smaller browsers such as Opera.
              You will need to have software install that can open files with .xlsx extensions.
              Deprication of .xls files for older Office versions pre 2008 are not supported.
              </h4></div>
          </div>
          <div class="row rowContainer mx-auto">
            <div class="col my-5">
              <h2>Internet connection is required for processing</h2>
            </div>
          </div>
          <div class="rowContainer">
            <ul class="list-group w-75 mx-auto">
                <li class="list-group-item">System Requirements</li>
                <li class="list-group-item">4 gigs of ram</li>
                <li class="list-group-item">A browser mentioned above</li>
            </ul>
          </div>
          <div class="rowContainer">
              <h1 class="my-5">Thats it!!!</h1>
          </div>
      </div>
  </div>
  </template>
