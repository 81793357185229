
<script>
export default {

}
</script>

<style>
.imgStyling {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
</style>
<template>
  <div>
      <h1>Do you struggle with collecting data from pdf's?</h1>
      <div class="row container w-75 mx-auto">
          <div class="col my-5">
              <h3>We have a lightweight solution that will work with any web browser</h3>
          </div>
          <div class="col my-5">
              <img class="imgStyling" src="images/researcher.jpg"/>
          </div>
      </div>
      <div class="row container w-75 mx-auto">
          <div class="col my-auto">
              <img class="imgStyling" src="images/hippa.jpg" />
          </div>
          <div class="col">
              <h3>This tool does not save anything to a database and is therefore safe to use in all medical facilities and county offices</h3>
          </div>
      </div>
      <div class="row my-5">
          <div class="col">
              <h3 class="my-3 w-75 mx-auto">This tool is more cost effective than others on the market</h3>
              <h3 class="my-3 w-75 mx-auto">It was created with the goal of reducing labor cost by streamlining data collection from huge files</h3>
          </div>
          <div class="col">
              <img class="imgStyling w-75" src="images/savings.jpg" />
          </div>
      </div>
      <div class="row">
          <div class="col">
              <img class="w-100" src="images/safety.jpg" />
              <h3 class="my-5 w-75 mx-auto">Data that is not specified by parameters provided will be cleared after each scan</h3>
          </div>
      </div>
  </div>
</template>
