
<script>
import HomeComp from '../components/homeComp.vue'
import SpecsComp from '../components/specsComp.vue'
import AboutComp from '../components/aboutComp.vue'

export default {
  name: 'Home',
  data () {
    return {
      showHome: false,
      showSpecs: false,
      showAbout: false
    }
  },
  methods: {
    toggleHome () {
      console.log('Home clicked')
      this.showHome = true
      this.showSpecs = false
      this.showAbout = false
    },
    toggleSpecs () {
      console.log('Specs clicked')
      this.showHome = false
      this.showSpecs = true
      this.showAbout = false
    },
    toggleAbout () {
      console.log('About clicked')
      this.showHome = false
      this.showSpecs = false
      this.showAbout = true
    }
  },
  components: {
    HomeComp,
    SpecsComp,
    AboutComp
  }
}
</script>
<style>
.bgContainer {
  width: 100%;
  height: 1000px;
  background-color: white;
}
.mainContainer {
  /* position: fixed; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 80%;
  height: 600px;
  background-color: rgb(211, 239, 248);
}
</style>
<template>
  <div>
  <div style="height: 100px; background-color: white;"></div>
  <div class="home bgContainer">
    <div class="mainContainer border rounded border-0 mx-auto mt-5">
      <div style="height: 200px"><h1 class="my-5">PT<label class="text-success">X</label> Solutions</h1></div>
      <div class="row border-bottom border-dark w-75 mx-auto">
        <div @click="toggleHome" v-if="!showHome" class="col"><h3>Home</h3></div>
        <div @click="toggleHome" v-if="showHome" class="col border-bottom border-success"><h3>Home</h3></div>
        <div @click="toggleSpecs" v-if="!showSpecs" class="col"><h3>Specs</h3></div>
        <div @click="toggleHome" v-if="showSpecs" class="col border-bottom border-success"><h3>Specs</h3></div>
        <div @click="toggleAbout" v-if="!showAbout" class="col"><h3>About</h3></div>
        <div @click="toggleHome" v-if="showAbout" class="col border-bottom border-success"><h3>About</h3></div>
      </div>
      <div v-if="showHome === true && showSpecs === false && showAbout === false" class="card border-0">
        <home-comp></home-comp>
      </div>
      <div v-if="showHome === false && showSpecs === true && showAbout === false" class="card border-0">
        <specs-comp></specs-comp>
      </div>
      <div v-if="showHome === false && showSpecs === false && showAbout === true" class="card border-0">
        <about-comp></about-comp>
      </div>
    </div>
  </div>
  </div>
</template>
